import React from 'react'
import Layout from '../components/Layout'
import { useSelector } from 'react-redux'
import { Divider } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import history from '../history'

const Profile = () => {
    const designerState = useSelector((state) => state.designer)
    const { designerInfo } = designerState

    return designerInfo ? (
        <Layout>
            <div className="profile-container">
                <div className="profile-box">
                    <div style={{ padding: '20px 0' }}>
                        <b>Personal info</b>
                    </div>
                    <div className="profile-personal-info-box">
                        <div className="profile-item">
                            <div className="profile-item-name">Name</div>
                            <div className="profile-item-value">
                                {designerInfo.firstName} {designerInfo.lastName}
                            </div>
                        </div>
                        <div className="profile-item">
                            <div className="profile-item-name">Email</div>
                            <div className="profile-item-value">
                                {designerInfo.email}
                            </div>
                        </div>
                        <div className="profile-item">
                            <div className="profile-item-name">
                                Phone Number
                            </div>
                            <div className="profile-item-value">
                                {designerInfo.phone}
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: '10px 0' }}></div>
                    <Divider />
                    <div style={{ padding: '20px 0' }}>
                        <b>Professional info</b>
                    </div>

                    <div className="profile-professional-info-box">
                        <div className="profile-item">
                            <div className="profile-item-name">
                                Year of experience
                            </div>
                            <div className="profile-item-value">
                                {designerInfo.experience}
                            </div>
                        </div>
                        {designerInfo.skills.map((skill, index) => (
                            <React.Fragment key={index}>
                                <div className="profile-item">
                                    <div className="profile-item-name">
                                        Your skills
                                    </div>
                                    <div className="profile-item-value">
                                        {skill.skill}
                                    </div>
                                </div>
                                <div className="profile-item">
                                    <div className="profile-item-name">
                                        Level
                                    </div>
                                    <div className="profile-item-value">
                                        {skill.level}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                        <div className="profile-item">
                            <div className="profile-item-name">Description</div>
                            <div
                                className="profile-item-value"
                                style={{ whiteSpace: 'pre-line' }}
                            >
                                {designerInfo.description}
                            </div>
                        </div>
                        <div className="profile-item">
                            <div className="profile-item-name">Education</div>
                            <div className="profile-item-value">
                                {designerInfo.education} (
                                {designerInfo.graduateYear})
                            </div>
                        </div>
                        {designerInfo.certifications.map(
                            (certification, index) => (
                                <React.Fragment key={index}>
                                    <div className="profile-item">
                                        <div className="profile-item-name">
                                            Certification
                                        </div>
                                        <div className="profile-item-value">
                                            {certification.certification} (
                                            {certification.certificationYear})
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        )}
                        <div className="profile-item">
                            <div className="profile-item-name">Website</div>
                            <div className="profile-item-value">
                                {designerInfo.website}
                            </div>
                        </div>
                        <div className="profile-item">
                            <div className="profile-item-name">Network</div>
                            <div className="profile-item-value">
                                {designerInfo.network}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-edit">
                    <div className="profile-edit-box">
                        <span
                            className="profile-edit-span"
                            onClick={() => history.push('/profileedit')}
                        >
                            <EditIcon
                                style={{
                                    color: '#FDDC3A',
                                    marginRight: '10px',
                                }}
                            />
                            Edit
                        </span>
                    </div>
                </div>
            </div>
        </Layout>
    ) : (
        ''
    )
}

export default Profile
