import { useState } from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { fildInput } from '../../module/inputForm'
import ProfessionalPhoto from './ProfessionalPhoto'
import { changeWelcomeProfessionalInfo } from '../../store/actions/welcomeActions'
import {
    changeTimeList,
    experenceList,
    firstDraftList,
    levelItems,
    skillItems,
} from '../../constant/default'
import DividerLine from '../DividerLine'
import ProfessionalPdf from './ProfessionalPdf'
import { removeDuplicates } from '../../helper/removeDuplicates'

const validateForm = (values) => {
    const errors = {}

    if (values.experience === '') {
        errors.experience = 'Required'
    }

    if (values.firstDraft === '') {
        errors.firstDraft = 'Required'
    }

    if (values.changeTime === '') {
        errors.changeTime = 'Required'
    }

    if (!values.education) {
        errors.education = 'Required'
    }

    if (!values.graduateYear) {
        errors.graduateYear = 'Required'
    }

    if (!values.checked) {
        errors.checked = 'Required'
    }

    const confirmSkillErrors = []

    values.skills.forEach((item, index) => {
        const skillsErrors = {}
        if (item.skill === '') {
            skillsErrors.skill = 'Required'
            confirmSkillErrors[index] = skillsErrors
        }
        if (item.level === '') {
            skillsErrors.level = 'Required'
            confirmSkillErrors[index] = skillsErrors
        }
    })

    if (confirmSkillErrors.length) {
        errors.skills = confirmSkillErrors
    }

    const confirmCerErrors = []

    values.certifications.forEach((item, index) => {
        const cerErrors = {}
        if (item.certification === '') {
            cerErrors.certification = 'Required'
            confirmCerErrors[index] = cerErrors
        }
        if (item.certificationYear === '') {
            cerErrors.certificationYear = 'Required'
            confirmCerErrors[index] = cerErrors
        }
    })

    if (confirmSkillErrors.length) {
        errors.skills = confirmSkillErrors
    }
    if (confirmCerErrors.length) {
        errors.certifications = confirmCerErrors
    }

    return errors
}

const ProfessionalInfoForm = () => {
    const dispatch = useDispatch()
    const welcomeState = useSelector((state) => state.welcome)
    const { professionalInfo } = welcomeState
    const professionalInfoFromLocal = JSON.parse(
        localStorage.getItem('HSDesignerProfessionalInfo')
    )

    const [photo1, setPhoto1] = useState(() => professionalInfo?.photo1 ?? null)
    const [photo2, setPhoto2] = useState(() => professionalInfo?.photo2 ?? null)
    const [photo3, setPhoto3] = useState(() => professionalInfo?.photo3 ?? null)
    const [photo4, setPhoto4] = useState(() => professionalInfo?.photo4 ?? null)
    const [photo5, setPhoto5] = useState(() => professionalInfo?.photo5 ?? null)
    const [pdf1, setPdf1] = useState(() => professionalInfo?.pdf1 ?? null)
    const [pdf2, setPdf2] = useState(() => professionalInfo?.pdf2 ?? null)

    return (
        <div>
            <Formik
                initialValues={
                    professionalInfoFromLocal
                        ? professionalInfoFromLocal
                        : {
                              checked: '',
                              experience: '',
                              website: '',
                              network: '',
                              education:
                                  professionalInfoFromLocal?.education ?? '',
                              graduateYear:
                                  professionalInfoFromLocal?.graduateYear ?? '',
                              firstDraft: '',
                              changeTime: '',
                              skills: [
                                  {
                                      skill: '',
                                      level: '',
                                  },
                              ],
                              certifications: [
                                  {
                                      certification: '',
                                      certificationYear: '',
                                  },
                              ],
                          }
                }
                validate={validateForm}
                onSubmit={async (values) => {
                    const tempSkills = removeDuplicates(values.skills)
                    const tempCer = removeDuplicates(values.certifications)

                    values = {
                        ...values,
                        skills: tempSkills,
                        certifications: tempCer,
                        photo1,
                        photo2,
                        photo3,
                        photo4,
                        photo5,
                        pdf1,
                        pdf2,
                    }
                    // console.log('values ==>>>', values)
                    dispatch(changeWelcomeProfessionalInfo(values))
                }}
            >
                {({ isSubmitting, errors, values }) => (
                    <Form>
                        <div className="welcome-personal-box">
                            <div className="professional-form-input">
                                <label htmlFor="experience">
                                    Year of experience
                                </label>
                                <Field
                                    as="select"
                                    name="experience"
                                    placeholder="Select"
                                    className="form-control"
                                >
                                    <option value="">Select</option>
                                    {experenceList.map((list) => (
                                        <option key={list.id} value={list.year}>
                                            {list.year}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="experience"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="professional-array-box">
                                <FieldArray name="skills">
                                    {({ insert, remove, push }) => (
                                        <>
                                            <div className="professional-array-items">
                                                {values?.skills?.length > 0 &&
                                                    values?.skills?.map(
                                                        (_, index) => (
                                                            <div
                                                                className="professional-array-bbox"
                                                                key={index}
                                                            >
                                                                <div className="professional-array-item">
                                                                    <label
                                                                        htmlFor={`skills.${index}.skill`}
                                                                    >
                                                                        Your
                                                                        skills
                                                                    </label>
                                                                    <Field
                                                                        as="select"
                                                                        name={`skills.${index}.skill`}
                                                                        placeholder="Skill"
                                                                        type="text"
                                                                        className="form-control"
                                                                    >
                                                                        <option value="">
                                                                            Select
                                                                        </option>
                                                                        {skillItems.map(
                                                                            (
                                                                                skill
                                                                            ) => (
                                                                                <option
                                                                                    key={
                                                                                        skill.id
                                                                                    }
                                                                                    value={
                                                                                        skill.skill
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        skill.skill
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage
                                                                        name={`skills.${index}.skill`}
                                                                        component="div"
                                                                        className="error-message"
                                                                    />
                                                                </div>
                                                                <div className="professional-array-item">
                                                                    <label
                                                                        htmlFor={`skills.${index}.level`}
                                                                    >
                                                                        Level
                                                                    </label>
                                                                    <Field
                                                                        as="select"
                                                                        name={`skills.${index}.level`}
                                                                        placeholder="Level"
                                                                        type="text"
                                                                        className="form-control"
                                                                    >
                                                                        <option value="">
                                                                            Select
                                                                        </option>
                                                                        {levelItems.map(
                                                                            (
                                                                                level
                                                                            ) => (
                                                                                <option
                                                                                    key={
                                                                                        level.id
                                                                                    }
                                                                                    value={
                                                                                        level.level
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        level.level
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage
                                                                        name={`skills.${index}.level`}
                                                                        component="div"
                                                                        className="error-message"
                                                                    />
                                                                </div>
                                                                {index !==
                                                                    0 && (
                                                                    <div className="professional-array-delete">
                                                                        <button
                                                                            type="button"
                                                                            className="professional-delete-button"
                                                                            onClick={() =>
                                                                                remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            <div className="professional-array-items">
                                                <span
                                                    onClick={() =>
                                                        push({
                                                            skill: '',
                                                            level: '',
                                                        })
                                                    }
                                                    className="professional-addmore-skills"
                                                >
                                                    Add more skills
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                            {/* Education start*/}

                            <div className="professional-form-2">
                                <div className="professional-form-2-left">
                                    {fildInput(
                                        'education',
                                        'Education',
                                        'text',
                                        errors.education
                                    )}
                                </div>
                                <div className="professional-form-2-right">
                                    {fildInput(
                                        'graduateYear',
                                        'Graduate year',
                                        'text',
                                        errors.graduateYear
                                    )}
                                </div>
                            </div>
                            {/* Education end */}

                            <div className="professional-array-box">
                                <FieldArray name="certifications">
                                    {({ insert, remove, push }) => (
                                        <>
                                            <div className="professional-array-items">
                                                {values?.certifications
                                                    ?.length > 0 &&
                                                    values?.certifications?.map(
                                                        (_, index) => (
                                                            <div
                                                                className="professional-array-bbox"
                                                                key={index}
                                                            >
                                                                <div className="professional-array-cef-left">
                                                                    <label
                                                                        htmlFor={`certifications.${index}.certification`}
                                                                    >
                                                                        Certification
                                                                    </label>
                                                                    <Field
                                                                        name={`certifications.${index}.certification`}
                                                                        placeholder="Certification"
                                                                        type="text"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`certifications.${index}.certification`}
                                                                        component="div"
                                                                        className="error-message"
                                                                    />
                                                                </div>
                                                                <div className="professional-array-cef-right">
                                                                    <label
                                                                        htmlFor={`certifications.${index}.certificationYear`}
                                                                    >
                                                                        Year
                                                                    </label>
                                                                    <Field
                                                                        name={`certifications.${index}.certificationYear`}
                                                                        placeholder="Year"
                                                                        type="text"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`certifications.${index}.certificationYear`}
                                                                        component="div"
                                                                        className="error-message"
                                                                    />
                                                                </div>
                                                                {index !==
                                                                    0 && (
                                                                    <div className="professional-array-delete">
                                                                        <button
                                                                            type="button"
                                                                            className="professional-delete-button"
                                                                            onClick={() =>
                                                                                remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            <div className="professional-array-items">
                                                <span
                                                    onClick={() =>
                                                        push({
                                                            certification: '',
                                                            certificationYear:
                                                                '',
                                                        })
                                                    }
                                                    className="professional-addmore-skills"
                                                >
                                                    Add more Certification
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </div>

                            {/* Website start */}

                            <div>
                                {fildInput('website', 'Website', 'text')}
                                {fildInput('network', 'Network', 'text')}
                            </div>
                        </div>
                        <DividerLine />
                        <div className="professional-upload-box">
                            <h3>Show your works</h3>
                            <p style={{ padding: '20px 0' }}>
                                Encourage clients to choose you by featuring
                                your best works.
                            </p>
                            <p style={{ padding: '20px 0', fontWeight: '600' }}>
                                Images (up to 5, Max 5M)
                            </p>
                            <div className="professional-form-photo">
                                <div className="professional-photo-box">
                                    <ProfessionalPhoto
                                        file={photo1}
                                        setFile={setPhoto1}
                                    />
                                </div>

                                <div className="professional-photo-box">
                                    <ProfessionalPhoto
                                        file={photo2}
                                        setFile={setPhoto2}
                                    />
                                </div>
                                <div className="professional-photo-box">
                                    <ProfessionalPhoto
                                        file={photo3}
                                        setFile={setPhoto3}
                                    />
                                </div>
                                <div className="professional-photo-box">
                                    <ProfessionalPhoto
                                        file={photo4}
                                        setFile={setPhoto4}
                                    />
                                </div>
                                <div className="professional-photo-box">
                                    <ProfessionalPhoto
                                        file={photo5}
                                        setFile={setPhoto5}
                                    />
                                </div>
                            </div>
                            <p style={{ padding: '20px 0', fontWeight: '600' }}>
                                Documents ( PDFs only, up to 2, Max 5M)
                            </p>
                            <div className="professional-form-photo">
                                <div className="professional-photo-box">
                                    <ProfessionalPdf
                                        pdf={pdf1}
                                        setPdf={setPdf1}
                                    />
                                </div>
                                <div className="professional-photo-box">
                                    <ProfessionalPdf
                                        pdf={pdf2}
                                        setPdf={setPdf2}
                                    />
                                </div>
                            </div>
                            <div className="professional-agree-box">
                                <div>
                                    <label>
                                        <Field type="checkbox" name="checked" />
                                    </label>
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                    I declare that those materials were created
                                    by myself or by my team and do not infringe
                                    on any 3rd party rights. <br />I understand
                                    that illegal use of digital assets may
                                    result in clocking my account.
                                </div>
                            </div>
                            <ErrorMessage
                                name="checked"
                                component="div"
                                className="error-message"
                            />
                        </div>
                        <DividerLine />
                        <div className="professional-delivery-box">
                            <h3 style={{ padding: '20px 0' }}>Delivery time</h3>
                            <div className="professional-delivery-items">
                                <div className="professional-delivery-items-left">
                                    <div className="professional-delivery-item">
                                        Delivery time for first draft:
                                    </div>
                                    <div className="professional-delivery-item">
                                        <Field
                                            as="select"
                                            name="firstDraft"
                                            placeholder="Select"
                                            className="form-control"
                                        >
                                            <option value="">Select</option>
                                            {firstDraftList.map(
                                                (list, index) => (
                                                    <option
                                                        key={index}
                                                        value={list.time}
                                                    >
                                                        {list.time}
                                                    </option>
                                                )
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="firstDraft"
                                            component="div"
                                            className="error-message"
                                        />
                                    </div>
                                </div>
                                <div className="professional-delivery-items-right">
                                    <div className="professional-delivery-item">
                                        Responsive time for changes:
                                    </div>
                                    <div className="professional-delivery-item">
                                        <Field
                                            as="select"
                                            name="changeTime"
                                            placeholder="Select"
                                            className="form-control"
                                        >
                                            <option value="">Select</option>
                                            {changeTimeList.map(
                                                (list, index) => (
                                                    <option
                                                        key={index}
                                                        value={list.time}
                                                    >
                                                        {list.time}
                                                    </option>
                                                )
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="changeTime"
                                            component="div"
                                            className="error-message"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DividerLine />
                        <div className="professional-button-box">
                            <button
                                type="submit"
                                className="professional-button"
                                disabled={isSubmitting}
                            >
                                Continue
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ProfessionalInfoForm
