import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { getErrors } from '../../store/actions/errorActions'

const ProfessionalPhoto = ({ file, setFile }) => {
    const dispatch = useDispatch()
    const [preView, setPreView] = useState(null)
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles[0]?.size > 5242880) {
                dispatch(getErrors('Over size! Max 5M'))
            } else {
                setFile(acceptedFiles[0])
            }
        },
    })

    useEffect(() => {
        if (file) {
            const imgUrl = URL.createObjectURL(file)
            setPreView(imgUrl)
        }
        return () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(file)
        }
    }, [file])

    return (
        <section className="container">
            <div {...getRootProps()} className="professional-input">
                <input {...getInputProps()} />
                {file ? (
                    <div className="professional-input-pic">
                        <div
                            className="professional-input-delete"
                            onClick={() => setFile(null)}
                        >
                            <CloseIcon style={{ color: '#ffffff' }} />
                        </div>
                        <img
                            src={preView}
                            alt="pic"
                            className="professional-pic"
                        />
                    </div>
                ) : (
                    <div className="professional-input-text">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1642560061/Request_Design/Screen_Shot_2022-01-18_at_9.40.43_PM_pymore.png"
                            alt="upload pic"
                            className="professional-uploadpic"
                        />
                        <p style={{ paddingTop: '5px' }}>Browse a Photo</p>
                    </div>
                )}
            </div>
        </section>
    )
}

export default ProfessionalPhoto
