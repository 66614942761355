import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import { useDispatch } from 'react-redux'
import { fildInput } from '../../module/inputForm'
import { profileUpdate } from '../../store/actions/welcomeActions'
import {
    changeTimeList,
    experenceList,
    firstDraftList,
    levelItems,
    skillItems,
} from '../../constant/default'
import DividerLine from '../DividerLine'
import { removeDuplicates } from '../../helper/removeDuplicates'

const validateForm = (values) => {
    const errors = {}

    if (!values.firstName) {
        errors.firstName = 'Required'
    } else if (values.firstName.length < 2) {
        errors.firstName = 'Must be 2 characters or more'
    } else if (values.firstName.length > 50) {
        errors.firstName = 'Must be less 50 characters'
    }

    if (!values.lastName) {
        errors.lastName = 'Required'
    } else if (values.lastName.length < 2) {
        errors.lastName = 'Must be 2 characters or more'
    } else if (values.lastName.length > 50) {
        errors.lastName = 'Must be less 50 characters'
    }

    if (!values.phone) {
        errors.phone = 'Required'
    } else if (
        !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            values.phone
        )
    ) {
        errors.phone = 'Invalid Phone number'
    }

    if (!values.description) {
        errors.description = 'Required'
    } else if (values.description.length > 300) {
        errors.description = 'Must be less 300 characters'
    }

    if (values.experience === '') {
        errors.experience = 'Required'
    }

    if (values.firstDraft === '') {
        errors.firstDraft = 'Required'
    }

    if (values.changeTime === '') {
        errors.changeTime = 'Required'
    }

    if (!values.education) {
        errors.education = 'Required'
    }

    if (!values.graduateYear) {
        errors.graduateYear = 'Required'
    }

    const confirmSkillErrors = []

    values.skills.forEach((item, index) => {
        const skillsErrors = {}
        if (item.skill === '') {
            skillsErrors.skill = 'Required'
            confirmSkillErrors[index] = skillsErrors
        }
        if (item.level === '') {
            skillsErrors.level = 'Required'
            confirmSkillErrors[index] = skillsErrors
        }
    })

    if (confirmSkillErrors.length) {
        errors.skills = confirmSkillErrors
    }

    const confirmCerErrors = []

    values.certifications.forEach((item, index) => {
        const cerErrors = {}
        if (item.certification === '') {
            cerErrors.certification = 'Required'
            confirmCerErrors[index] = cerErrors
        }
        if (item.certificationYear === '') {
            cerErrors.certificationYear = 'Required'
            confirmCerErrors[index] = cerErrors
        }
    })

    if (confirmSkillErrors.length) {
        errors.skills = confirmSkillErrors
    }

    if (confirmCerErrors.length) {
        errors.certifications = confirmCerErrors
    }

    return errors
}

const ProfileForm = ({ designerInfo }) => {
    const dispatch = useDispatch()
    return (
        <div>
            {' '}
            <Formik
                initialValues={designerInfo}
                validate={validateForm}
                onSubmit={async (values) => {
                    const tempSkills = removeDuplicates(values.skills)
                    const tempCer = removeDuplicates(values.certifications)
                    values = {
                        ...values,
                        skills: tempSkills,
                        certifications: tempCer,
                    }
                    // console.log(values)
                    dispatch(profileUpdate(values))
                }}
            >
                {({ isSubmitting, errors, values }) => (
                    <Form>
                        <div className="personal-form-box">
                            <div className="personal-form-left">
                                {fildInput(
                                    'firstName',
                                    'First Name',
                                    'text',
                                    errors.firstName
                                )}
                                {fildInput(
                                    'lastName',
                                    'Last Name',
                                    'text',
                                    errors.lastName
                                )}
                                {fildInput(
                                    'phone',
                                    'Phone number',
                                    'text',
                                    errors.phone
                                )}
                            </div>
                        </div>
                        <div className="form-input-div">
                            <label htmlFor="description">Description</label>
                            <Field
                                as="textarea"
                                name="description"
                                rows="10"
                                className={`form-control-textarea ${
                                    errors.description ? 'error' : ''
                                }`}
                            />

                            <div className="textarea-counter">
                                {values.description?.length || 0}/300
                            </div>
                        </div>
                        <div style={{ padding: '0 10px' }}>
                            <ErrorMessage
                                name="description"
                                component="div"
                                style={{ color: 'red', marginTop: '5px' }}
                            />
                        </div>

                        <div className="welcome-personal-box">
                            <div className="professional-form-input">
                                <label htmlFor="experience">
                                    Year of experience
                                </label>
                                <Field
                                    as="select"
                                    name="experience"
                                    placeholder="Select"
                                    className="form-control"
                                >
                                    <option value="">Select</option>
                                    {experenceList.map((list) => (
                                        <option key={list.id} value={list.year}>
                                            {list.year}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage
                                    name="experience"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="professional-array-box">
                                <FieldArray name="skills">
                                    {({ insert, remove, push }) => (
                                        <>
                                            <div className="professional-array-items">
                                                {values?.skills?.length > 0 &&
                                                    values?.skills?.map(
                                                        (_, index) => (
                                                            <div
                                                                className="professional-array-bbox"
                                                                key={index}
                                                            >
                                                                <div className="professional-array-item">
                                                                    <label
                                                                        htmlFor={`skills.${index}.skill`}
                                                                    >
                                                                        Your
                                                                        skills
                                                                    </label>
                                                                    <Field
                                                                        as="select"
                                                                        name={`skills.${index}.skill`}
                                                                        placeholder="Skill"
                                                                        type="text"
                                                                        className="form-control"
                                                                    >
                                                                        <option value="">
                                                                            Select
                                                                        </option>
                                                                        {skillItems.map(
                                                                            (
                                                                                skill,
                                                                                skillIndex
                                                                            ) => (
                                                                                <option
                                                                                    key={
                                                                                        skillIndex
                                                                                    }
                                                                                    value={
                                                                                        skill.skill
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        skill.skill
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage
                                                                        name={`skills.${index}.skill`}
                                                                        component="div"
                                                                        className="error-message"
                                                                    />
                                                                </div>
                                                                <div className="professional-array-item">
                                                                    <label
                                                                        htmlFor={`skills.${index}.level`}
                                                                    >
                                                                        Level
                                                                    </label>
                                                                    <Field
                                                                        as="select"
                                                                        name={`skills.${index}.level`}
                                                                        placeholder="Level"
                                                                        type="text"
                                                                        className="form-control"
                                                                    >
                                                                        <option value="">
                                                                            Select
                                                                        </option>
                                                                        {levelItems.map(
                                                                            (
                                                                                level,
                                                                                levelIndex
                                                                            ) => (
                                                                                <option
                                                                                    key={
                                                                                        levelIndex
                                                                                    }
                                                                                    value={
                                                                                        level.level
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        level.level
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage
                                                                        name={`skills.${index}.level`}
                                                                        component="div"
                                                                        className="error-message"
                                                                    />
                                                                </div>
                                                                {index !==
                                                                    0 && (
                                                                    <div className="professional-array-delete">
                                                                        <button
                                                                            type="button"
                                                                            className="professional-delete-button"
                                                                            onClick={() =>
                                                                                remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            <div className="professional-array-items">
                                                <span
                                                    onClick={() =>
                                                        push({
                                                            skill: '',
                                                            level: '',
                                                        })
                                                    }
                                                    className="professional-addmore-skills"
                                                >
                                                    Add more skills
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                            {/* Education start*/}

                            <div className="professional-form-2">
                                <div className="professional-form-2-left">
                                    {fildInput(
                                        'education',
                                        'Education',
                                        'text',
                                        errors.education
                                    )}
                                </div>
                                <div className="professional-form-2-right">
                                    {fildInput(
                                        'graduateYear',
                                        'Graduate year',
                                        'text',
                                        errors.graduateYear
                                    )}
                                </div>
                            </div>
                            {/* Education end */}

                            <div className="professional-array-box">
                                <FieldArray name="certifications">
                                    {({ insert, remove, push }) => (
                                        <>
                                            <div className="professional-array-items">
                                                {values?.certifications
                                                    ?.length > 0 &&
                                                    values?.certifications?.map(
                                                        (_, index) => (
                                                            <div
                                                                className="professional-array-bbox"
                                                                key={index}
                                                            >
                                                                <div className="professional-array-cef-left">
                                                                    <label
                                                                        htmlFor={`certifications.${index}.certification`}
                                                                    >
                                                                        Certification
                                                                    </label>
                                                                    <Field
                                                                        name={`certifications.${index}.certification`}
                                                                        placeholder="Certification"
                                                                        type="text"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`certifications.${index}.certification`}
                                                                        component="div"
                                                                        className="error-message"
                                                                    />
                                                                </div>
                                                                <div className="professional-array-cef-right">
                                                                    <label
                                                                        htmlFor={`certifications.${index}.certificationYear`}
                                                                    >
                                                                        Year
                                                                    </label>
                                                                    <Field
                                                                        name={`certifications.${index}.certificationYear`}
                                                                        placeholder="Year"
                                                                        type="text"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage
                                                                        name={`certifications.${index}.certificationYear`}
                                                                        component="div"
                                                                        className="error-message"
                                                                    />
                                                                </div>
                                                                {index !==
                                                                    0 && (
                                                                    <div className="professional-array-delete">
                                                                        <button
                                                                            type="button"
                                                                            className="professional-delete-button"
                                                                            onClick={() =>
                                                                                remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            <div className="professional-array-items">
                                                <span
                                                    onClick={() =>
                                                        push({
                                                            certification: '',
                                                            certificationYear:
                                                                '',
                                                        })
                                                    }
                                                    className="professional-addmore-skills"
                                                >
                                                    Add more Certification
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </div>

                            {/* Website start */}

                            <div>
                                {fildInput('website', 'Website', 'text')}
                                {fildInput('network', 'Network', 'text')}
                            </div>
                        </div>

                        <DividerLine />
                        <div className="professional-delivery-box">
                            <h3 style={{ padding: '20px 0' }}>Delivery time</h3>
                            <div className="professional-delivery-items">
                                <div className="professional-delivery-items-left">
                                    <div className="professional-delivery-item">
                                        Delivery time for first draft:
                                    </div>
                                    <div className="professional-delivery-item">
                                        <Field
                                            as="select"
                                            name="firstDraft"
                                            placeholder="Select"
                                            className="form-control"
                                        >
                                            <option value="">Select</option>
                                            {firstDraftList.map(
                                                (list, index) => (
                                                    <option
                                                        key={index}
                                                        value={list.time}
                                                    >
                                                        {list.time}
                                                    </option>
                                                )
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="firstDraft"
                                            component="div"
                                            className="error-message"
                                        />
                                    </div>
                                </div>
                                <div className="professional-delivery-items-right">
                                    <div className="professional-delivery-item">
                                        Responsive time for changes:
                                    </div>
                                    <div className="professional-delivery-item">
                                        <Field
                                            as="select"
                                            name="changeTime"
                                            placeholder="Select"
                                            className="form-control"
                                        >
                                            <option value="">Select</option>
                                            {changeTimeList.map(
                                                (list, index) => (
                                                    <option
                                                        key={index}
                                                        value={list.time}
                                                    >
                                                        {list.time}
                                                    </option>
                                                )
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="changeTime"
                                            component="div"
                                            className="error-message"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DividerLine />

                        <div className="personal-button">
                            <button
                                type="submit"
                                className="login-button"
                                disabled={isSubmitting}
                            >
                                Update
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ProfileForm
