import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { fildInput } from '../module/inputForm'
import DisplayMessages from '../components/DisplayMessages'
import { forgetPasswordAction } from '../store/actions/designerActions'

const validateForm = (values) => {
    const errors = {}

    if (!values.email) {
        errors.email = 'Required'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address'
    }
    return errors
}

const ForgetPassword = () => {
    const dispatch = useDispatch()
    const designerState = useSelector((state) => state.designer)
    const { forgetPassword } = designerState
    const recaptchaRef = useRef()

    return (
        <div className="joinus-box">
            <div className="joinus-box-item">
                <h3 style={{ textAlign: 'center', padding: '30px 0' }}>
                    FORGOT YOUR PASSWORD?
                </h3>
                <div>
                    <DisplayMessages />
                </div>
                {forgetPassword ? (
                    <>
                        <div>
                            Please check your eamil to reset your password
                        </div>
                    </>
                ) : (
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validate={validateForm}
                        onSubmit={async (values) => {
                            const googleRes =
                                await recaptchaRef.current.executeAsync()
                            recaptchaRef.current.reset()
                            values['googleRes'] = googleRes
                            console.log(values)
                            dispatch(forgetPasswordAction(values))
                        }}
                    >
                        {({ isSubmitting, errors }) => (
                            <Form>
                                <div>
                                    <p>
                                        Please enter your email address below to
                                        receive a password reset link.
                                    </p>
                                </div>
                                {fildInput(
                                    'email',
                                    'Your email',
                                    'email',
                                    errors.email
                                )}

                                <div className="google-robert">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey={
                                            process.env
                                                .REACT_APP_GOOGLE_SITE_KEY
                                        }
                                    />
                                </div>

                                <div
                                    className="login-button-area"
                                    style={{ padding: '20px 0' }}
                                >
                                    <button
                                        type="submit"
                                        className="login-button"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    )
}

export default ForgetPassword
