import { useDispatch, useSelector } from 'react-redux'
import DividerLine from '../DividerLine'
import WelcomeHeader from './WelcomeHeader'
import EmailIcon from '@mui/icons-material/Email'
import { uploadCloudinary } from './uploadCloudinary'
import { designerProfileUpdate } from '../../store/actions/welcomeActions'
import { upLoadItem } from '../../store/actions/loadingActions'

const AccountSecurity = () => {
    const dispatch = useDispatch()
    const welcomeState = useSelector((state) => state.welcome)
    const { personalInfo, professionalInfo } = welcomeState

    const submitInfo = async () => {
        const values = { ...personalInfo, ...professionalInfo }
        delete values.avatar
        delete values.photo1
        delete values.photo2
        delete values.photo3
        delete values.photo4
        delete values.photo5
        delete values.pdf1
        delete values.pdf2

        const avatar = personalInfo.avatar
        if (avatar !== '') {
            dispatch(upLoadItem('avatar'))
            const avatarLink = await uploadCloudinary(avatar, dispatch)
            values['avatar'] = avatarLink
            dispatch(upLoadItem(null))
        }
        const photoList = [
            'photo1',
            'photo2',
            'photo3',
            'photo4',
            'photo5',
            'pdf1',
            'pdf2',
        ]
        for (let i = 0; i < photoList.length; i++) {
            const photo = professionalInfo[photoList[i]]
            if (photo) {
                dispatch(upLoadItem(photoList[i]))
                const photoLink = await uploadCloudinary(photo, dispatch)
                values[photoList[i]] = photoLink
                dispatch(upLoadItem(null))
            }
        }

        console.log('value ==>>', values)
        dispatch(designerProfileUpdate(values))
    }
    return (
        <div>
            <WelcomeHeader />
            <div className="welcome-personal-box">
                <h3 className="welcome-inside-title">Account Security</h3>
                <p>
                    Trust and safety is a big deal in our community. Please
                    verify your email and phone number so that we can keep your
                    account secured.
                </p>
                <DividerLine />
                <div className="welcome-security-box">
                    <div className="welcome-security-left">
                        <div style={{ paddingRight: '20px' }}>
                            <EmailIcon />
                        </div>
                        <div>Email</div>
                    </div>
                    <div>
                        <div className="welcome-security-verified">
                            Verified
                        </div>
                    </div>
                </div>
            </div>
            <div className="welcome-security-button-box">
                <button
                    type="button"
                    className="professional-button"
                    onClick={() => submitInfo()}
                >
                    Complete
                </button>
            </div>
        </div>
    )
}

export default AccountSecurity
