import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '20px 0',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

const Footer = () => {
    const classes = useStyles()
    return (
        <>
            <Container disableGutters={true}>
                <Divider className={classes.divider} />
                <div className="footer-box">
                    <div>
                        <Link to="/">
                            <img
                                src="https://res.cloudinary.com/homespace/image/upload/v1622233552/logo/HomeSpace_Logo-09_igr9cr.png"
                                alt="logo"
                                width="100px"
                                className="footer_logo"
                                style={{ objectFit: 'contain' }}
                            />
                        </Link>
                    </div>
                    <div className="header-word-box">
                        <span className="footer-items">
                            Unit 1, 854 Westport Cres.
                            <br /> Mississauga, ON L5T 1N5
                        </span>
                        <span className="footer-items">
                            T: 1-833-770-7077 <br />
                            E: info@hs-online.com
                        </span>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Footer
