import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Container from '@material-ui/core/Container'
import { confirmEmail } from '../store/actions/designerActions'

function ConfirmEmail({ match }) {
    const dispatch = useDispatch()
    const token = match.params.token
    useEffect(() => {
        dispatch(confirmEmail(token))
    }, [dispatch, token])

    return (
        <Container>
            <div
                style={{
                    width: '100%',
                    height: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <h3>We are checking your account...</h3>
            </div>
        </Container>
    )
}

export default ConfirmEmail
