export const SHOW_LOADING = 'SHOW_LOADING'
export const GET_ERRORS = 'GET_ERRORS'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'

export const DESIGNER_LOGOIN_SUCCESS = 'DESIGNER_LOGOIN_SUCCESS'
export const DESIGNER_LOGOUT = 'DESIGNER_LOGOUT'
export const DESIGNER_UPDATE_INFO = 'DESIGNER_UPDATE_INFO'
export const DESIGNER_UPDATE_TOKEN = 'DESIGNER_UPDATE_TOKEN'

export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE'
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE'

export const CHANGE_WELCOME_STAGE = 'CHANGE_WELCOME_STAGE'
export const CHANGE_WELCOME_PERSONALINFO = 'CHANGE_WELCOME_PERSONALINFO'
export const CHANGE_WELCOME_PROFESSIONALINFO = 'CHANGE_WELCOME_PROFESSIONALINFO'

export const UPLOAD_ITEM = 'UPLOAD_ITEM'
export const FORGET_PASSWORD = 'FORGET_PASSWORD'

export const GET_ALL_REQUEST_DESIGNS = 'GET_ALL_REQUEST_DESIGNS'
export const SEARCH_REQUEST_DESIGNS = 'SEARCH_REQUEST_DESIGNS'
export const CHANGE_REQUEST_DESIGNS_PAGE = 'CHANGE_REQUEST_DESIGNS_PAGE'
export const GET_ONE_REQUEST_DESIGN = 'GET_ONE_REQUEST_DESIGN'
