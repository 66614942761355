import React from 'react'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import {
    createDesignerSubmit,
    updateDesignerSubmit,
} from '../../store/actions/designerSubmitActions'
import { FORM_TYPE_EDIT } from '../../constant/default'

const validateForm = (values) => {
    const errors = {}
    if (!values.link) {
        errors.link = 'Required'
    }
    if (!values.picLink) {
        errors.picLink = 'Required'
    }
    return errors
}

const DesignerSubmitForm = ({
    handleDialogClose,
    formType,
    initV,
    requestDesignId,
}) => {
    const dispatch = useDispatch()
    return (
        <>
            <Formik
                initialValues={{
                    link: initV.link,
                    picLink: initV.picLink,
                }}
                validate={validateForm}
                onSubmit={async (values) => {
                    values['requestDesignId'] = requestDesignId
                    values['id'] = initV?.id
                    console.log(values)
                    dispatch(
                        formType === FORM_TYPE_EDIT
                            ? updateDesignerSubmit(values)
                            : createDesignerSubmit(values)
                    )
                    handleDialogClose()
                }}
            >
                {({ isSubmitting, errors }) => (
                    <Form>
                        <DialogContent>
                            <DialogContentText>
                                Please enter all informations below, click{' '}
                                {formType === FORM_TYPE_EDIT
                                    ? 'Update'
                                    : 'Submit'}{' '}
                                button to finish.
                            </DialogContentText>
                        </DialogContent>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '20px',
                            }}
                        >
                            <label htmlFor="description">Link</label>
                            <Field as="textarea" name="link" rows="10" />
                            <div style={{ padding: '0 10px' }}>
                                <ErrorMessage
                                    name="link"
                                    component="div"
                                    style={{ color: 'red', marginTop: '5px' }}
                                />
                            </div>
                            <label htmlFor="description">Picture Link</label>
                            <Field name="picLink" />
                            <div style={{ padding: '0 10px' }}>
                                <ErrorMessage
                                    name="picLink"
                                    component="div"
                                    style={{ color: 'red', marginTop: '5px' }}
                                />
                            </div>
                        </div>
                        <DialogActions>
                            <Button
                                onClick={handleDialogClose}
                                variant="outlined"
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                disabled={isSubmitting}
                            >
                                {formType === FORM_TYPE_EDIT
                                    ? 'Update'
                                    : 'Submit'}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default DesignerSubmitForm
