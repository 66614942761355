import Container from '@material-ui/core/Container'
import BlockIcon from '@material-ui/icons/Block'

function Default() {
    return (
        <Container>
            <div
                style={{
                    width: '100%',
                    height: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <BlockIcon
                    style={{ fontSize: 30, color: 'red', marginRight: '10px' }}
                />{' '}
                <h3>Page Not Found!</h3>
            </div>
        </Container>
    )
}

export default Default
