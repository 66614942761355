import { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { fildInput } from '../../module/inputForm'
import { changeWelcomePersonalInfo } from '../../store/actions/welcomeActions'
import DragDrop from './DragDrop'

const validateForm = (values) => {
    const errors = {}
    if (!values.firstName) {
        errors.firstName = 'Required'
    } else if (values.firstName.length < 2) {
        errors.firstName = 'Must be 2 characters or more'
    } else if (values.firstName.length > 50) {
        errors.firstName = 'Must be less 50 characters'
    }

    if (!values.lastName) {
        errors.lastName = 'Required'
    } else if (values.lastName.length < 2) {
        errors.lastName = 'Must be 2 characters or more'
    } else if (values.lastName.length > 50) {
        errors.lastName = 'Must be less 50 characters'
    }

    if (!values.phone) {
        errors.phone = 'Required'
    } else if (
        !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            values.phone
        )
    ) {
        errors.phone = 'Invalid Phone number'
    }

    if (!values.description) {
        errors.description = 'Required'
    } else if (values.description.length > 300) {
        errors.description = 'Must be less 300 characters'
    }
    return errors
}

const PersonalInfoForm = () => {
    const personalInfoFromLocal = JSON.parse(
        localStorage.getItem('HSDesignerPersonalInfo')
    )
    const welcomeState = useSelector((state) => state.welcome)
    const { personalInfo } = welcomeState
    const [avatar, setAvatar] = useState(() => personalInfo?.avatar ?? null)
    // console.log(avatar)
    const dispatch = useDispatch()

    return (
        <div>
            <Formik
                initialValues={{
                    firstName: personalInfoFromLocal?.firstName ?? '',
                    lastName: personalInfoFromLocal?.lastName ?? '',
                    phone: personalInfoFromLocal?.phone ?? '',
                    description: personalInfoFromLocal?.description ?? '',
                }}
                validate={validateForm}
                onSubmit={async (values) => {
                    values['avatar'] = avatar
                    console.log(values)
                    dispatch(changeWelcomePersonalInfo(values))
                }}
            >
                {({ isSubmitting, errors, values }) => (
                    <Form>
                        <div className="personal-form-box">
                            <div className="personal-form-left">
                                {fildInput(
                                    'firstName',
                                    'First Name',
                                    'text',
                                    errors.firstName
                                )}
                                {fildInput(
                                    'lastName',
                                    'Last Name',
                                    'text',
                                    errors.lastName
                                )}
                                {fildInput(
                                    'phone',
                                    'Phone number',
                                    'text',
                                    errors.phone
                                )}
                            </div>
                            <div className="personal-form-right">
                                {' '}
                                <DragDrop file={avatar} setFile={setAvatar} />
                            </div>
                        </div>
                        <div className="form-input-div">
                            <label htmlFor="description">Description</label>
                            <Field
                                as="textarea"
                                name="description"
                                rows="10"
                                className={`form-control-textarea ${
                                    errors.description ? 'error' : ''
                                }`}
                            />

                            <div className="textarea-counter">
                                {values.description?.length || 0}/300
                            </div>
                        </div>
                        <div style={{ padding: '0 10px' }}>
                            <ErrorMessage
                                name="description"
                                component="div"
                                style={{ color: 'red', marginTop: '5px' }}
                            />
                        </div>

                        <div className="personal-button">
                            <button
                                type="submit"
                                className="login-button"
                                disabled={isSubmitting}
                            >
                                Continue
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default PersonalInfoForm
