import {
    CHANGE_WELCOME_PERSONALINFO,
    CHANGE_WELCOME_PROFESSIONALINFO,
    CHANGE_WELCOME_STAGE,
    DESIGNER_UPDATE_INFO,
} from '../types'
import { logout, renewUserToken } from './designerActions'
import { newToken } from '../../admin/newtoken'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { getErrors } from './errorActions'
import history from '../../history'

export const changeWelcomeStage = (stage) => async (dispatch) => {
    dispatch({
        type: CHANGE_WELCOME_STAGE,
        payload: stage,
    })
}

export const changeWelcomePersonalInfo = (values) => async (dispatch) => {
    localStorage.setItem('HSDesignerPersonalInfo', JSON.stringify(values))
    dispatch({
        type: CHANGE_WELCOME_PERSONALINFO,
        payload: values,
    })
    dispatch({
        type: CHANGE_WELCOME_STAGE,
        payload: 2,
    })
}

export const changeWelcomeProfessionalInfo = (values) => async (dispatch) => {
    localStorage.setItem('HSDesignerProfessionalInfo', JSON.stringify(values))
    dispatch({
        type: CHANGE_WELCOME_PROFESSIONALINFO,
        payload: values,
    })
    dispatch({
        type: CHANGE_WELCOME_STAGE,
        payload: 3,
    })
}

export const designerProfileUpdate = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            designer: { designerToken, designerInfo },
        } = getState()
        let res = await request.put(
            `designer/designeruser/updateprofile/${designerInfo.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${designerToken}` },
            }
        )

        localStorage.removeItem('HSDesignerProfessionalInfo')
        localStorage.removeItem('HSDesignerPersonalInfo')
        dispatch({
            type: CHANGE_WELCOME_PROFESSIONALINFO,
            payload: null,
        })
        dispatch({
            type: CHANGE_WELCOME_PERSONALINFO,
            payload: null,
        })
        dispatch({
            type: CHANGE_WELCOME_STAGE,
            payload: 0,
        })

        let resValues = res.data.data
        resValues = {
            ...resValues,
            skills: res.data.data.skills.split('&').map((item) => {
                const a = item.split('||')
                return { skill: a[0], level: a[1] }
            }),
            certifications: res.data.data.certifications
                .split('&')
                .map((item) => {
                    const a = item.split('||')
                    return { certification: a[0], certificationYear: a[1] }
                }),
        }
        newToken(res, dispatch, logout)
        dispatch(renewUserToken(res))
        localStorage.setItem('HSOnlineDesignerInfo', JSON.stringify(resValues))
        dispatch({ type: DESIGNER_UPDATE_INFO, payload: resValues })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const profileUpdate = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            designer: { designerToken, designerInfo },
        } = getState()
        let res = await request.put(
            `designer/designeruser/updateprofile/${designerInfo.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${designerToken}` },
            }
        )
        let resValues = res.data.data
        resValues = {
            ...resValues,
            skills: res.data.data.skills.split('&').map((item) => {
                const a = item.split('||')
                return { skill: a[0], level: a[1] }
            }),
            certifications: res.data.data.certifications
                .split('&')
                .map((item) => {
                    const a = item.split('||')
                    return { certification: a[0], certificationYear: a[1] }
                }),
        }
        newToken(res, dispatch, logout)
        dispatch(renewUserToken(res))
        localStorage.setItem('HSOnlineDesignerInfo', JSON.stringify(resValues))
        dispatch({ type: DESIGNER_UPDATE_INFO, payload: resValues })
        dispatch(controlLoading(false))
        history.push('/profile')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
