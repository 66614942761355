import { errorsReturn } from '../errorsReturn'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './designerActions'
import { getOneRequestDesign } from './requestDesignActions'

export const createDesignerSubmit = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            designer: { designerToken },
        } = getState()
        let res = await request.post(`designer/designersubmit/create`, values, {
            headers: { Authorization: `Bearer ${designerToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getOneRequestDesign(values.requestDesignId))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateDesignerSubmit = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            designer: { designerToken },
        } = getState()
        let res = await request.put(
            `designer/designersubmit/update/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${designerToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getOneRequestDesign(values.requestDesignId))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteDesignerSubmit =
    (id, requestDesignId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                designer: { designerToken },
            } = getState()
            let res = await request.delete(
                `designer/designersubmit/delete/${id}`,
                {
                    headers: { Authorization: `Bearer ${designerToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getOneRequestDesign(requestDesignId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
