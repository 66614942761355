import { useDispatch, useSelector } from 'react-redux'
import { changeWelcomeStage } from '../../store/actions/welcomeActions'
import DividerLine from '../DividerLine'

const navLists = [
    { id: 1, title: 'Personal Info' },
    { id: 2, title: 'Professional Info' },
    { id: 3, title: 'Account Security' },
]

const WelcomeHeader = () => {
    const dispatch = useDispatch()
    const welcomeState = useSelector((state) => state.welcome)
    const { stage } = welcomeState

    const clickFunc = (stage) => {
        dispatch(changeWelcomeStage(stage))
    }

    return (
        <>
            <div className="welcome-header-box">
                {navLists.map((list) => (
                    <div className="welcome-header-items" key={list.id}>
                        <span
                            className={`checkout-number ${
                                list.id < stage ? 'click' : ''
                            }`}
                            style={
                                stage === list.id
                                    ? { background: '#FDDC3A' }
                                    : { background: '#D6D6D6' }
                            }
                            onClick={() =>
                                list.id < stage ? clickFunc(list.id) : null
                            }
                        >
                            {list.id}
                        </span>
                        <span className="welcome-header-title">
                            {list.title}
                        </span>
                        {navLists.length !== list.id && (
                            <span className="welcome-header-symbol">{'>'}</span>
                        )}
                    </div>
                ))}
            </div>
            <DividerLine />
        </>
    )
}

export default WelcomeHeader
