import { useEffect } from 'react'
import DividerLine from '../DividerLine'
import PersonalInfoForm from './PersonalInfoForm'
import WelcomeHeader from './WelcomeHeader'

const PersonalInfo = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <WelcomeHeader />
            <div className="welcome-personal-box">
                <h3 className="welcome-inside-title">Personal Info</h3>
                <p>
                    Tell us about your self. This information will appear on
                    your profile, so that clients can get to know you better.
                </p>
                <DividerLine />
                <PersonalInfoForm />
            </div>
        </div>
    )
}

export default PersonalInfo
