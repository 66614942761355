import { useDropzone } from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { getErrors } from '../../store/actions/errorActions'

const ProfessionalPdf = ({ pdf, setPdf }) => {
    const dispatch = useDispatch()
    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles[0]?.size > 5242880) {
                dispatch(getErrors('Over size! Max 5M'))
            } else {
                setPdf(acceptedFiles[0])
            }
        },
    })

    return (
        <section className="container">
            <div {...getRootProps()} className="professional-input">
                <input {...getInputProps()} />
                {pdf ? (
                    <div className="professional-input-pic">
                        <div
                            className="professional-input-delete"
                            onClick={() => setPdf(null)}
                        >
                            <CloseIcon style={{ color: '#ffffff' }} />
                        </div>
                        <img
                            src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615948739/samples/pdf_nw6xuu.png"
                            alt="pic"
                            className="professional-pic"
                        />
                    </div>
                ) : (
                    <div className="professional-input-text">
                        <p style={{ paddingTop: '5px' }}>Browse a PDF</p>
                    </div>
                )}
            </div>
        </section>
    )
}

export default ProfessionalPdf
