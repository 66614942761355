import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { messageReducer } from './messageReducer'
import { errorReducer } from './errorReducer'
import loadingReducer from './loadingReducer'
import { displayMessagesReducer } from './displayMessagesReducer'
import designerReducer from './designerReducer'
import welcomeReducer from './welcomeReducer'
import requestDesignReducer from './requestDesignReducer'

export default combineReducers({
    form: formReducer,
    errors: errorReducer,
    messages: messageReducer,
    loading: loadingReducer,
    displayMessages: displayMessagesReducer,
    designer: designerReducer,
    welcome: welcomeReducer,
    requestDesign: requestDesignReducer,
})
