import { Button, Chip, TableCell, TableRow } from '@material-ui/core'
import { buildTypeLists, designStatus } from '../../constant/default'
import moment from 'moment'
import history from '../../history'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: '5px',
    },
}))

const chipColor = ['secondary', 'primary', 'default', 'primary', 'primary']

const RequestDesignList = ({ requestDesign }) => {
    const classes = useStyles()
    return (
        <>
            <TableRow>
                <TableCell>
                    <Chip
                        label={
                            designStatus.filter(
                                (item) => item.id === requestDesign?.status
                            )[0]?.name
                        }
                        color={chipColor[parseInt(requestDesign?.status) - 1]}
                    />
                </TableCell>
                <TableCell>{requestDesign?.projectNo}</TableCell>
                <TableCell>
                    {
                        buildTypeLists.filter(
                            (list) => list.id === requestDesign?.buildType
                        )[0].name
                    }
                </TableCell>
                <TableCell>
                    {requestDesign?.firstName} {requestDesign?.lastName}
                </TableCell>
                <TableCell>
                    {moment(requestDesign?.createdAt).format('ll')}
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        onClick={() =>
                            history.push(
                                `/requestdesignone/${requestDesign.id}`
                            )
                        }
                    >
                        info
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default RequestDesignList
