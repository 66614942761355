import { useState } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import Alert from './components/Alert'
import Backdrop from './components/backDrop/Backdrop'
import Footer from './components/Footer'
import Header from './components/Header'
import LoadingSet from './components/LoadingSet'
import history from './history'
import ConfirmEmail from './pages/ConfirmEmail'
import Default from './pages/Default'
import ForgetPassword from './pages/ForgetPassword'
import Home from './pages/Home'
import Joinus from './pages/Joinus'
import Login from './pages/Login'
import Profile from './pages/Profile'
import ProfileEdit from './pages/ProfileEdit'
import RequestDesignInfo from './pages/RequestDesignInfo'
import RestPassword from './pages/RestPassword'
import SigninSuccess from './pages/SigninSuccess'
import Software from './pages/Software'
import Unconfirmed from './pages/Unconfirmed'
import Welcome from './pages/Welcome'

function App() {
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
    const backdropClickHandler = () => {
        setSideDrawerOpen(false)
    }
    return (
        <Router history={history}>
            <div className="main__page">
                <Alert />
                <LoadingSet />
                {sideDrawerOpen && <Backdrop click={backdropClickHandler} />}
                <Header />

                <main className="main__body">
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/login" component={Login} />
                        <Route path="/joinus" component={Joinus} />
                        <Route path="/welcome" component={Welcome} />
                        <Route path="/unconfirmed" component={Unconfirmed} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/profileedit" component={ProfileEdit} />
                        <Route path="/software" component={Software} />
                        <Route
                            path="/forgetpassword"
                            component={ForgetPassword}
                        />
                        <Route
                            path="/confirmemail/:token"
                            component={ConfirmEmail}
                        />
                        <Route
                            path="/resetpassword/:token"
                            component={RestPassword}
                        />
                        <Route
                            path="/signinsuccessful/:id"
                            component={SigninSuccess}
                        />
                        <Route
                            path="/requestdesignone/:id"
                            component={RequestDesignInfo}
                        />

                        <Route component={Default} />
                    </Switch>
                </main>
                <Footer />
            </div>
        </Router>
    )
}

export default App
