import {
    DESIGNER_LOGOIN_SUCCESS,
    DESIGNER_LOGOUT,
    DESIGNER_UPDATE_INFO,
    DESIGNER_UPDATE_TOKEN,
    FORGET_PASSWORD,
} from '../types'

const initialState = {
    designerInfo: null,
    designerToken: null,
    forgetPassword: null,
}

const designerReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case DESIGNER_LOGOIN_SUCCESS:
            return {
                ...state,
                designerInfo: payload.designerInfo,
                designerToken: payload.designerToken,
            }
        case DESIGNER_UPDATE_INFO:
            return { ...state, designerInfo: payload }

        case DESIGNER_LOGOUT:
            return {
                designerInfo: null,
                designerToken: null,
            }
        case DESIGNER_UPDATE_TOKEN:
            return { ...state, designerToken: payload }
        case FORGET_PASSWORD:
            return {
                ...state,
                forgetPassword: payload,
            }
        default:
            return state
    }
}

export default designerReducer
