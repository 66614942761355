import { useDispatch } from 'react-redux'
import BadgeIcon from '@mui/icons-material/Badge'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import GppGoodIcon from '@mui/icons-material/GppGood'
import EditIcon from '@mui/icons-material/Edit'
import { changeWelcomeStage } from '../../store/actions/welcomeActions'

const listItems = [
    {
        id: 1,
        icon: <BadgeIcon />,
        title: 'Take your time in creating your profile, put a face to your name!',
    },
    {
        id: 2,
        icon: <AccessTimeIcon />,
        title: 'Always respond on time will make the case successful.',
    },
    {
        id: 3,
        icon: <GppGoodIcon />,
        title: 'To keep our community secure, we may ask you to verify your ID.',
    },
    {
        id: 4,
        icon: <EditIcon />,
        title: 'Accurate describe your design skills and link out your professional network.',
    },
]

const WelcomeFirstPage = () => {
    const dispatch = useDispatch()
    return (
        <>
            <h2 className="welcome-title">Welcome to Hspace Team!</h2>
            <div className="welcome-detail">
                <div className="welcome-left">
                    <img
                        src="https://res.cloudinary.com/home-space/image/upload/v1642299427/Request_Design/Screen_Shot_2022-01-15_at_9.16.48_PM_t0wwxj.png"
                        alt="welcome pic"
                        className="welcome-pic"
                    />
                </div>
                <div className="welcome-right">
                    <h3>Tips for making a successful profile</h3>
                    <p style={{ padding: '10px 0' }}>
                        Your first impression matters! Create a profile with
                        your best project.
                    </p>
                    <div className="welcome-items">
                        {listItems.map((item) => (
                            <div className="welcome-item" key={item.id}>
                                <div className="welcome-item-icon">
                                    {item.icon}
                                </div>
                                <div className="welcome-item-title">
                                    {item.title}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="welcome-button-area">
                        <button
                            className="welcome-button"
                            onClick={() => dispatch(changeWelcomeStage(1))}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WelcomeFirstPage
