import React from 'react'
import { useSelector } from 'react-redux'
import Layout from '../components/Layout'
import ProfileForm from '../components/profile/ProfileForm'

const ProfileEdit = () => {
    const designerState = useSelector((state) => state.designer)
    const { designerInfo } = designerState
    return (
        <Layout>
            <div>
                <ProfileForm designerInfo={designerInfo} />
            </div>
        </Layout>
    )
}

export default ProfileEdit
