import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container } from '@material-ui/core'
import history from '../history'
import DashBoardHeader from './dashBoard/DashBoardHeader'

const Layout = ({ children }) => {
    const designerState = useSelector((state) => state.designer)
    const { designerInfo } = designerState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!designerInfo) {
            history.replace('/login')
        }
        if (designerInfo && designerInfo?.status === 1) {
            history.replace('/welcome')
        }
        if (
            designerInfo &&
            designerInfo?.status === 2 &&
            !designerInfo?.isActive
        ) {
            history.replace('/unconfirmed')
        }
    }, [designerInfo])

    return (
        designerInfo && (
            <Container disableGutters={true}>
                {designerInfo &&
                    designerInfo?.status === 2 &&
                    designerInfo?.isActive && <DashBoardHeader />}
                <div className="layout-box">
                    <div className="layout-children">{children}</div>
                </div>
            </Container>
        )
    )
}

export default Layout
