import {
    CHANGE_WELCOME_PERSONALINFO,
    CHANGE_WELCOME_PROFESSIONALINFO,
    CHANGE_WELCOME_STAGE,
} from '../types'

const initialState = {
    stage: 0,
    personalInfo: null,
    professionalInfo: null,
    accountSecurity: null,
}

const welcomeReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case CHANGE_WELCOME_STAGE:
            return {
                ...state,
                stage: payload,
            }
        case CHANGE_WELCOME_PERSONALINFO:
            return {
                ...state,
                personalInfo: payload,
            }
        case CHANGE_WELCOME_PROFESSIONALINFO:
            return {
                ...state,
                professionalInfo: payload,
            }
        default:
            return state
    }
}

export default welcomeReducer
