import { DISPLAY_ERROR_MESSAGE, DISPLAY_MESSAGE } from '../types'

const initialState = {
    displayMessage: null,
    displayErrorMessage: null,
}

export const displayMessagesReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case DISPLAY_MESSAGE:
            return {
                ...state,
                displayMessage: payload,
            }
        case DISPLAY_ERROR_MESSAGE:
            return {
                ...state,
                displayErrorMessage: payload,
            }
        default:
            return state
    }
}
