export const ACCOUNT_ACTIVED =
    'Your account already actived, please login to your account'
export const RESEND_EMAIL_SUCCESS = 'Email sent successfully'
export const DESIGNER_PROFILE_UPDATE_SUCCESS = 'Profile updated successfully'
export const FORM_TYPE_NEW = 'new'
export const FORM_TYPE_EDIT = 'edit'

export const skillItems = [
    { id: 1, skill: '2020 Design (must have)' },
    { id: 2, skill: 'AutoCAD' },
    { id: 3, skill: 'SketchUp' },
    { id: 4, skill: 'TurboCAD' },
    { id: 4, skill: 'Adobe Photoshop' },
]

export const levelItems = [
    { id: 1, level: 'Entry level' },
    { id: 2, level: 'Intermediate level' },
    { id: 3, level: 'Professional level' },
]

export const experenceList = [
    { id: 1, year: '0-2' },
    { id: 2, year: '3-5' },
    { id: 3, year: '5-9' },
    { id: 4, year: '10+' },
]

export const firstDraftList = [
    { id: 1, time: '0-6 hours' },
    { id: 2, time: 'Within 24hr' },
    { id: 2, time: 'Less then 2 days' },
]

export const changeTimeList = [
    { id: 1, time: '0-6 hours' },
    { id: 2, time: 'Within 24hr' },
    { id: 2, time: 'Less then 2 days' },
]

export const buildTypeLists = [
    { id: 1, name: 'Kitchen' },
    { id: 2, name: 'Bathroom' },
    { id: 3, name: 'Other' },
]

export const designStatus = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Detail' },
    { id: 3, name: 'Designer' },
    { id: 4, name: 'Submit' },
    { id: 5, name: 'Finished' },
]
