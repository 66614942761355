import { useDispatch } from 'react-redux'
import Container from '@material-ui/core/Container'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { resendConfirmEmail } from '../store/actions/designerActions'

function SigninSuccess({ match }) {
    const dispatch = useDispatch()
    const id = match.params.id
    return (
        <Container>
            <div
                style={{
                    width: '100%',
                    height: '50vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CheckCircle
                    style={{
                        fontSize: 100,
                        color: 'green',
                        marginRight: '10px',
                    }}
                />{' '}
                <h3 style={{ marginTop: '30px', marginBottom: '30px' }}>
                    YOU ARE ALMOST THERE! JUST CONFIRM YOU EMAIL
                </h3>
                <p>
                    You’ve successfully created a Space account. To activate it,
                    please check your email address, thanks!
                </p>
                <p>
                    If you didn't receive the email, you can click the below
                    button to resend the email
                </p>
                <button
                    className="resend-email-button"
                    onClick={() => dispatch(resendConfirmEmail(id))}
                >
                    Resend Email
                </button>
            </div>
        </Container>
    )
}

export default SigninSuccess
