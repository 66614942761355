import { useSelector } from 'react-redux'
import Layout from '../components/Layout'
import AccountSecurity from '../components/welcome/AccountSecurity'
import PersonalInfo from '../components/welcome/PersonalInfo'
import ProfessionalInfo from '../components/welcome/ProfessionalInfo'
import WelcomeFirstPage from '../components/welcome/WelcomeFirstPage'
import UpLoadItem from '../components/UpLoadItem'

const Welcome = () => {
    const welcomeState = useSelector((state) => state.welcome)
    const { stage } = welcomeState

    return (
        <Layout>
            {stage === 0 && <WelcomeFirstPage />}
            {stage === 1 && <PersonalInfo />}
            {stage === 2 && <ProfessionalInfo />}
            {stage === 3 && <AccountSecurity />}
            <UpLoadItem />
        </Layout>
    )
}

export default Welcome
