const Loader = () => {
    return (
        <div className="loading-page">
            {/* <img
                src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1616609279/samples/loading_mqn5mz.gif"
                alt="loading"
                width="150px"
                height="150px"
            /> */}
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <span className="lds-logo">
                    <img
                        src="https://res.cloudinary.com/home-space/image/upload/v1644893077/Logo/Website_Loading_icon_q5n2xa.png"
                        alt="logo"
                        className="lds-logo-pic"
                    />
                </span>
            </div>
        </div>
    )
}

export default Loader
