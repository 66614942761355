import React from 'react'
import Layout from '../components/Layout'

const Software = () => {
    return (
        <Layout>
            <div>this is software</div>
        </Layout>
    )
}

export default Software
