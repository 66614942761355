import { Card, CardContent, CardHeader } from '@material-ui/core'
import React from 'react'

const RequestDesignDetailInfo = ({ requestDesign }) => {
    // const {
    //     bulkhead,
    //     bulkheadDepth,
    //     bulkheadHeight,
    //     range,
    //     rangeSize,
    //     rangeSizeOther,
    //     rangeType,
    //     rangeHood,
    //     rangeHoodOther,
    //     refrigeratorWidth,
    //     refrigeratorDepth,
    //     refrigeratorHeight,
    //     microwave,
    //     microwaveWidth,
    //     microwaveDepth,
    //     microwaveHeight,
    //     dishwasherSize,
    //     dishwasherSizeOther,
    //     dishwasherLocation,
    //     dishwasherLocationOther,
    //     sinkWidth,
    //     sinkDepth,
    //     sink,
    //     sinkOther,
    //     handles,
    //     cabinetStyle,
    //     upperWallCabinetHeight,
    //     upperWallCabinetHeightOther,
    //     cabinetsStorage,
    //     baseStorage,
    //     wallCabinet,
    //     wallCabinetOther,
    //     trim,
    //     note,
    // } = requestDesign?.requestdesigndetail

    const sizeMeasure = requestDesign?.sizeMeasure === 1 ? 'ft' : 'm'

    return requestDesign && requestDesign.requestdesigndetail ? (
        <>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Client Name" />
                <CardContent>
                    <div>First name: {requestDesign.firstName}</div>
                    <div>Last name: {requestDesign.lastName}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Room size" />
                <CardContent>
                    <div className="request-design-room">
                        <div className="request-design-room-item">
                            Width: {requestDesign.width} {sizeMeasure}
                        </div>
                        <div className="request-design-room-item">
                            Length: {requestDesign.length} {sizeMeasure}
                        </div>
                        <div className="request-design-room-item">
                            Height: {requestDesign.height} {sizeMeasure}
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardContent>
                    <div className="request-design-kitchen-images-box">
                        <div className="request-design-kitchen-images-left">
                            <img
                                src="https://res.cloudinary.com/home-space/image/upload/v1640031570/Request_Design/Screen_Shot_2021-12-20_at_3.19.25_PM_juufqf.png"
                                className="request-design-kitchen-size-pic"
                                alt="size pic"
                            />
                        </div>
                        <div className="request-design-kitchen-images-right">
                            {requestDesign.photo1 && (
                                <div className="request-design-kitchen-images-photo">
                                    <h3>Side 1 Photo</h3>
                                    <img
                                        src={requestDesign.photo1}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                    />
                                </div>
                            )}
                            {requestDesign.photo2 && (
                                <div className="request-design-kitchen-images-photo">
                                    <h3>Side 2 Photo</h3>
                                    <img
                                        src={requestDesign.photo2}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                    />
                                </div>
                            )}
                            {requestDesign.photo3 && (
                                <div className="request-design-kitchen-images-photo">
                                    <h3>Side 3 Photo</h3>
                                    <img
                                        src={requestDesign.photo3}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                    />
                                </div>
                            )}
                            {requestDesign.photo4 && (
                                <div className="request-design-kitchen-images-photo">
                                    <h3>Side 4 Photo</h3>
                                    <img
                                        src={requestDesign.photo4}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Existing Construction" />
                <CardContent>
                    <div>
                        Ceiling Height:{' '}
                        {requestDesign?.requestdesigndetail?.ceilingHeight}
                    </div>
                    <div>
                        Bulkhead Height:{' '}
                        {requestDesign?.requestdesigndetail?.bulkhead}
                    </div>
                    {requestDesign?.requestdesigndetail?.bulkhead === 'Yes' && (
                        <>
                            <div>
                                Bulkhead Size Depth:{' '}
                                {
                                    requestDesign?.requestdesigndetail
                                        ?.bulkheadDepth
                                }
                            </div>
                            <div>
                                Bulkhead Size Height:{' '}
                                {
                                    requestDesign?.requestdesigndetail
                                        ?.bulkheadHeight
                                }
                            </div>
                        </>
                    )}
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Range / Oven" />
                <CardContent>
                    <div>{requestDesign?.requestdesigndetail?.range}</div>
                    <div>
                        {requestDesign?.requestdesigndetail?.rangeSize ===
                        'Other'
                            ? requestDesign?.requestdesigndetail?.rangeSizeOther
                            : requestDesign?.requestdesigndetail?.rangeSize}
                    </div>
                    <div>{requestDesign?.requestdesigndetail?.rangeType}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Range Hood / Ventilation " />
                <CardContent>
                    <div>
                        {requestDesign?.requestdesigndetail?.rangeHood ===
                        'Other'
                            ? requestDesign?.requestdesigndetail?.rangeHoodOther
                            : requestDesign?.requestdesigndetail?.rangeHood}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Refrigerator" />
                <CardContent>
                    <div>
                        Width:{' '}
                        {requestDesign?.requestdesigndetail?.refrigeratorWidth}
                    </div>
                    <div>
                        Depth:{' '}
                        {requestDesign?.requestdesigndetail?.refrigeratorDepth}
                    </div>
                    <div>
                        Height:{' '}
                        {requestDesign?.requestdesigndetail?.refrigeratorHeight}
                    </div>
                </CardContent>
            </Card>

            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Microwave" />
                <CardContent>
                    <div>{requestDesign?.requestdesigndetail?.microwave}</div>
                    <div>
                        Width:{' '}
                        {requestDesign?.requestdesigndetail?.microwaveWidth}
                    </div>
                    <div>
                        Depth:{' '}
                        {requestDesign?.requestdesigndetail?.microwaveDepth}
                    </div>
                    <div>
                        Height:{' '}
                        {requestDesign?.requestdesigndetail?.microwaveHeight}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Dishwasher" />
                <CardContent>
                    <div>
                        {requestDesign?.requestdesigndetail?.dishwasherSize ===
                        'Other'
                            ? requestDesign?.requestdesigndetail
                                  ?.dishwasherSizeOther
                            : requestDesign?.requestdesigndetail
                                  ?.dishwasherSize}
                    </div>
                    <div>
                        {requestDesign?.requestdesigndetail
                            ?.dishwasherLocation === 'Other'
                            ? requestDesign?.requestdesigndetail
                                  ?.dishwasherLocationOther
                            : requestDesign?.requestdesigndetail
                                  ?.dishwasherLocation}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Sink" />
                <CardContent>
                    <div>
                        Width: {requestDesign?.requestdesigndetail?.sinkWidth}
                    </div>
                    <div>
                        Depth: {requestDesign?.requestdesigndetail?.sinkDepth}
                    </div>
                    <div>
                        {requestDesign?.requestdesigndetail?.sink === 'Other'
                            ? requestDesign?.requestdesigndetail?.sinkOther
                            : requestDesign?.requestdesigndetail?.sink}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Handles / Knobs" />
                <CardContent>
                    <div>{requestDesign?.requestdesigndetail?.handles}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Cabinet Style & Height" />
                <CardContent>
                    <div>
                        {requestDesign?.requestdesigndetail?.cabinetStyle}
                    </div>
                    <div>
                        Upper Wall Cabinet Height:{' '}
                        {requestDesign?.requestdesigndetail
                            ?.upperWallCabinetHeight === 'Other'
                            ? requestDesign?.requestdesigndetail
                                  ?.upperWallCabinetHeightOther
                            : requestDesign?.requestdesigndetail
                                  ?.upperWallCabinetHeight}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Cabinets & Storage Options" />
                <CardContent>
                    <div>
                        {requestDesign?.requestdesigndetail?.cabinetsStorage}
                    </div>
                    <div>
                        Preference for base storage:{' '}
                        {requestDesign?.requestdesigndetail?.baseStorage}
                    </div>

                    <div>
                        Preference for wall cabinet:{' '}
                        {requestDesign?.requestdesigndetail?.wallCabinet ===
                        'Other'
                            ? requestDesign?.requestdesigndetail
                                  ?.wallCabinetOther
                            : requestDesign?.requestdesigndetail?.wallCabinet}
                    </div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Trim & Moldings" />
                <CardContent>
                    <div>{requestDesign?.requestdesigndetail?.trim}</div>
                </CardContent>
            </Card>
            <Card style={{ margin: '20px 0' }}>
                <CardHeader title="Extra Notes" />
                <CardContent>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {requestDesign?.requestdesigndetail?.note}
                    </div>
                </CardContent>
            </Card>
        </>
    ) : (
        ''
    )
}

export default RequestDesignDetailInfo
