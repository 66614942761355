import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { getErrors } from '../../store/actions/errorActions'

const DragDrop = ({ file, setFile }) => {
    const dispatch = useDispatch()
    const [preView, setPreView] = useState(null)
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles[0].size > 5242880) {
                dispatch(getErrors('Over size! Max 5M'))
            } else {
                setFile(acceptedFiles[0])
            }
        },
    })

    useEffect(() => {
        if (file) {
            const imgUrl = URL.createObjectURL(file)
            setPreView(imgUrl)
        }
        return () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(file)
        }
    }, [file])

    return (
        <section className="container">
            <div {...getRootProps()} className="drag-drop-input">
                <input {...getInputProps()} />
                {file ? (
                    <div className="drag-drop-input-pic">
                        <div
                            className="drag-drop-input-delete"
                            onClick={() => setFile(null)}
                        >
                            <CloseIcon style={{ color: '#ffffff' }} />
                        </div>
                        <img
                            src={preView}
                            alt="pic"
                            className="drag-drop-pic"
                        />
                    </div>
                ) : (
                    <div className="drag-drop-input-text">
                        <p>Profile Picture</p>
                    </div>
                )}
            </div>
        </section>
    )
}

export default DragDrop
