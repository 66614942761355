import { errorsReturn } from '../errorsReturn'
import { GET_ALL_REQUEST_DESIGNS, GET_ONE_REQUEST_DESIGN } from '../types'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './designerActions'

export const getAllRequestDesigns =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                designer: { designerToken },
            } = getState()
            let res = await request.get(
                `designer/designeruser/requestdesignlist?keyword=${keyword}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${designerToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_REQUEST_DESIGNS,
                payload: {
                    requestDesigns: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const getOneRequestDesign = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            designer: { designerToken, designerInfo },
        } = getState()
        let res = await request.get(
            `designer/designeruser/requestdesignone/${id}`,
            {
                headers: { Authorization: `Bearer ${designerToken}` },
            }
        )

        const data =
            designerInfo.level === 1
                ? {
                      ...res.data.data,
                      designersubmits: res.data.data.designersubmits.filter(
                          (item) => item.designer.id === designerInfo.id
                      ),
                  }
                : res.data.data

        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_REQUEST_DESIGN,
            payload: data,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
