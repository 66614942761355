import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '20px 0',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

const DividerLine = () => {
    const classes = useStyles()
    return (
        <>
            <Divider className={classes.divider} />
        </>
    )
}

export default DividerLine
