import React from 'react'
import { DialogTitle } from '@material-ui/core'
import DesignerSubmitForm from './DesignerSubmitForm'

const DesignerSubmit = ({
    handleDialogClose,
    requestDesignId,
    formType,
    submitValue,
}) => {
    return (
        <div>
            <DialogTitle id="form-dialog-title">Design Submit</DialogTitle>
            <DesignerSubmitForm
                handleDialogClose={handleDialogClose}
                formType={formType}
                initV={submitValue}
                requestDesignId={requestDesignId}
            />
        </div>
    )
}

export default DesignerSubmit
