import request from '../../config/request'
import {
    ACCOUNT_ACTIVED,
    DESIGNER_PROFILE_UPDATE_SUCCESS,
    RESEND_EMAIL_SUCCESS,
} from '../../constant/default'
import history from '../../history'
import { errorsReturn } from '../errorsReturn'
import {
    DESIGNER_LOGOIN_SUCCESS,
    DESIGNER_LOGOUT,
    DESIGNER_UPDATE_TOKEN,
    DISPLAY_ERROR_MESSAGE,
    FORGET_PASSWORD,
} from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'
import { createMessage } from './messageActions'

export const login = (values) => async (dispatch) => {
    if (values.rememberMe) {
        localStorage.setItem('HSDesignerRemember', JSON.stringify(values.email))
    } else {
        localStorage.removeItem('HSDesignerRemember')
    }
    try {
        dispatch(controlLoading(true))
        const { data } = await request.post(
            'designer/designerauth/login',
            values
        )
        let resValues = data.data.designer
        resValues = {
            ...resValues,
            skills: data.data.designer.skills.split('&').map((item) => {
                const a = item.split('||')
                return { skill: a[0], level: a[1] }
            }),
            certifications: data.data.designer.certifications
                .split('&')
                .map((item) => {
                    const a = item.split('||')
                    return { certification: a[0], certificationYear: a[1] }
                }),
        }
        localStorage.setItem('HSOnlineDesignerInfo', JSON.stringify(resValues))
        localStorage.setItem(
            'HSOnlineDesignerToken',
            JSON.stringify(data.data.token)
        )
        dispatch({
            type: DESIGNER_LOGOIN_SUCCESS,
            payload: {
                designerInfo: resValues,
                designerToken: data.data.token,
            },
        })
        dispatch(controlLoading(false))
    } catch (e) {
        // errorsReturn(e, dispatch, controlLoading, getErrors)
        const errors = {
            msg: e?.response?.data?.msg,
        }
        dispatch(controlLoading(false))
        dispatch({ type: DISPLAY_ERROR_MESSAGE, payload: errors?.msg })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('HSOnlineDesignerInfo')
    localStorage.removeItem('HSOnlineDesignerToken')
    dispatch({ type: DESIGNER_LOGOUT })
}

export const joinus = (values) => async (dispatch) => {
    try {
        dispatch(controlLoading(true))

        let res = await request.post(`designer/designeruser/`, values)
        dispatch(controlLoading(false))
        history.push(`/signinsuccessful/${res.data.data.id}`)
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const resendConfirmEmail = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))

        await request.get(`designer/designeruser/resendconfirmemail/${id}`)
        dispatch(createMessage(RESEND_EMAIL_SUCCESS))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const confirmEmail = (token) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))

        await request.get(`designer/designeruser/confirmation/${token}`)
        dispatch(createMessage(ACCOUNT_ACTIVED))
        dispatch(controlLoading(false))
        history.push('/login')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const renewUserToken = (values) => (dispatch) => {
    const newTokenValue = values.headers.authorization
    dispatch({
        type: DESIGNER_UPDATE_TOKEN,
        payload: newTokenValue,
    })
}

export const forgetPasswordAction = (values) => async (dispatch) => {
    try {
        dispatch(controlLoading(true))
        await request.post(`designer/designeruser/forgetpassword`, values)
        dispatch({ type: FORGET_PASSWORD, payload: true })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const resetPasswordAction = (values) => async (dispatch) => {
    try {
        dispatch(controlLoading(true))
        await request.post(`designer/designeruser/resetpassword`, values)
        dispatch(createMessage('Password reset successful'))
        dispatch(controlLoading(false))
        history.push('/login')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
