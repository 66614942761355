import { Container } from '@material-ui/core'
import React from 'react'
import Layout from '../components/Layout'

const Unconfirmed = () => {
    return (
        <Layout>
            <Container>
                <div>
                    Thank you for choosing Hspace, we will review your profile
                    as soon as possible, we will notify you once confirmed!
                </div>
            </Container>
        </Layout>
    )
}

export default Unconfirmed
