import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DISPLAY_ERROR_MESSAGE, DISPLAY_MESSAGE } from '../store/types'

// function usePrevious(value) {
//     const ref = useRef()
//     useEffect(() => {
//         ref.current = value
//     })
//     return ref.current
// }

const DisplayMessages = () => {
    const dispatch = useDispatch()
    const displayMessagesState = useSelector((state) => state.displayMessages)
    const { displayMessage, displayErrorMessage } = displayMessagesState

    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)

    useEffect(() => {
        if (displayErrorMessage) {
            setError(displayErrorMessage)
            setTimeout(() => {
                setError(null)
                dispatch({ type: DISPLAY_ERROR_MESSAGE, payload: null })
            }, 10000)
        }
        if (displayMessage) {
            setMessage(displayMessage)
            setTimeout(() => {
                setMessage(null)
                dispatch({ type: DISPLAY_MESSAGE, payload: null })
            }, 10000)
        }
    }, [displayErrorMessage, displayMessage, dispatch])

    return (
        <div className="display-message-box">
            {error && (
                <div className="display-message error">× Error. {error}</div>
            )}
            {message && (
                <div className="display-message message">{message}</div>
            )}
        </div>
    )
}

export default DisplayMessages
