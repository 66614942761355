import { Button, Card, CardHeader, CardContent, Table } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import RequestDesignDetailInfo from '../components/requestDesigns/RequestDesignDetailInfo'
import history from '../history'
import { getOneRequestDesign } from '../store/actions/requestDesignActions'
import { Divider, Dialog } from '@material-ui/core'
import DesignerSubmit from '../components/designerSubmit/DesignerSubmit'
import { deleteDesignerSubmit } from '../store/actions/designerSubmitActions'
import { FORM_TYPE_NEW, FORM_TYPE_EDIT } from '../constant/default'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { green } from '@material-ui/core/colors'

const RequestDesignInfo = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()
    const [formType, setFormType] = useState(FORM_TYPE_NEW)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [submitValue, setSubmitValue] = useState({ id: '', link: '' })

    const handleDialogOpen = (type, value) => {
        setFormType(type)
        setSubmitValue(value)
        setDialogOpen(true)
    }

    const handleDialogClose = () => {
        setDialogOpen(false)
    }

    const requestDesignState = useSelector((state) => state.requestDesign)
    const { requestDesign } = requestDesignState

    useEffect(() => {
        dispatch(getOneRequestDesign(id))
    }, [dispatch, id])

    console.log(requestDesign)
    return (
        <Layout>
            {requestDesign ? (
                <div>
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                            style={{ margin: '20px 0' }}
                        >
                            Back
                        </Button>
                    </div>
                    <Divider />
                    {requestDesign.status < 5 && (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ margin: '20px 0' }}
                                onClick={() =>
                                    handleDialogOpen(FORM_TYPE_NEW, {
                                        id: '',
                                        link: '',
                                    })
                                }
                            >
                                Submit design
                            </Button>
                        </div>
                    )}
                    {requestDesign.designersubmits &&
                        requestDesign.designersubmits.length > 0 && (
                            <Card>
                                <CardHeader title="Designer Submit" />
                                <CardContent>
                                    <Table>
                                        <tbody>
                                            {requestDesign.designersubmits.map(
                                                (submit) => (
                                                    <tr
                                                        key={submit.id}
                                                        style={{
                                                            margin: '10px 0',
                                                        }}
                                                    >
                                                        <td>
                                                            <img
                                                                src={
                                                                    submit.picLink
                                                                }
                                                                style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    objectFit:
                                                                        'contain',
                                                                }}
                                                                alt="piclink"
                                                                onError={(
                                                                    event
                                                                ) => {
                                                                    event.target.src =
                                                                        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                                                    event.onerror =
                                                                        null
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <a
                                                                href={
                                                                    submit.link
                                                                }
                                                                target="_blank"
                                                            >
                                                                Link
                                                            </a>{' '}
                                                        </td>
                                                        <td>
                                                            {requestDesign.status >=
                                                                3 &&
                                                                !submit.confirm && (
                                                                    <>
                                                                        <Button
                                                                            variant="outlined"
                                                                            size="small"
                                                                            color="inherit"
                                                                            style={{
                                                                                margin: '10px 20px',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDialogOpen(
                                                                                    FORM_TYPE_EDIT,
                                                                                    submit
                                                                                )
                                                                            }
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                        <Button
                                                                            variant="outlined"
                                                                            size="small"
                                                                            color="secondary"
                                                                            onClick={() => {
                                                                                if (
                                                                                    window.confirm(
                                                                                        'are you sure?'
                                                                                    )
                                                                                ) {
                                                                                    dispatch(
                                                                                        deleteDesignerSubmit(
                                                                                            submit.id,
                                                                                            requestDesign.id
                                                                                        )
                                                                                    )
                                                                                }
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </>
                                                                )}
                                                        </td>
                                                        <td>
                                                            {submit.selected && (
                                                                <CheckCircleOutlineIcon
                                                                    style={{
                                                                        color: green[500],
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                </CardContent>
                            </Card>
                        )}
                    <RequestDesignDetailInfo requestDesign={requestDesign} />
                    <Dialog
                        open={dialogOpen}
                        // onClose={handleDialogClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DesignerSubmit
                            handleDialogClose={handleDialogClose}
                            requestDesignId={requestDesign.id}
                            formType={formType}
                            submitValue={submitValue}
                        />
                    </Dialog>
                </div>
            ) : (
                ''
            )}
        </Layout>
    )
}

export default RequestDesignInfo
