import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { logout } from '../store/actions/designerActions'
import history from '../history'

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '20px 0',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

const Header = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const designerState = useSelector((state) => state.designer)
    const { designerInfo } = designerState
    return (
        <>
            <Container disableGutters={true}>
                <div className="header-box">
                    <div>
                        <Link to="/">
                            <img
                                src="https://res.cloudinary.com/homespace/image/upload/v1622233552/logo/HomeSpace_Logo-09_igr9cr.png"
                                alt="logo"
                                width="100px"
                                className="footer_logo"
                                style={{ objectFit: 'contain' }}
                            />
                        </Link>
                    </div>
                    <div className="header-word-box">
                        {designerInfo && designerInfo.firstName ? (
                            <span className="header-items">
                                Hi {designerInfo.firstName}{' '}
                                {designerInfo.lastName}
                            </span>
                        ) : (
                            <>
                                <span
                                    className="header-items"
                                    onClick={() => history.push('/joinus')}
                                >
                                    Become a designer
                                </span>
                                <span
                                    className="header-items"
                                    onClick={() => history.push('/login')}
                                >
                                    Designer Login
                                </span>
                            </>
                        )}
                        {designerInfo && (
                            <span
                                className="header-items"
                                onClick={() => dispatch(logout())}
                            >
                                Logout
                            </span>
                        )}
                    </div>
                </div>
                <Divider className={classes.divider} />
            </Container>
        </>
    )
}

export default Header
