import { SHOW_LOADING, UPLOAD_ITEM } from '../types'

export const controlLoading = (value) => ({
    type: SHOW_LOADING,
    payload: value,
})

export const upLoadItem = (value) => ({
    type: UPLOAD_ITEM,
    payload: value,
})
