import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

const designerInfoFromStorage = localStorage.getItem('HSOnlineDesignerInfo')
    ? JSON.parse(localStorage.getItem('HSOnlineDesignerInfo'))
    : null

const designerTokenFromStorage = localStorage.getItem('HSOnlineDesignerToken')
    ? JSON.parse(localStorage.getItem('HSOnlineDesignerToken'))
    : null

const middleware = [thunk]

const initialState = {
    designer: {
        designerInfo: designerInfoFromStorage,
        designerToken: designerTokenFromStorage,
    },
}

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
    // applyMiddleware(...middleware)
)

export default store
