import { CREATE_MESSAGE } from '../types'

const initialState = {
    message: null,
}

export const messageReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case CREATE_MESSAGE:
            return {
                ...state,
                message: payload,
            }
        default:
            return state
    }
}
