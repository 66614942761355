import DividerLine from '../DividerLine'
import ProfessionalInfoForm from './ProfessionalInfoForm'
import WelcomeHeader from './WelcomeHeader'

const ProfessionalInfo = () => {
    return (
        <div>
            <WelcomeHeader />
            <div className="welcome-personal-box">
                <h3 className="welcome-inside-title">Professional Info</h3>
                <p>
                    This is your time to shine. Let clients know what you do
                    best.
                </p>
                <DividerLine />
            </div>
            <ProfessionalInfoForm />
        </div>
    )
}

export default ProfessionalInfo
