import { Divider } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import history from '../../history'

const navBarList = [
    { id: 1, title: 'Cases', link: '/' },
    { id: 2, title: 'Personal Profile', link: '/profile' },
    { id: 3, title: '2020 Design Software', link: '/software' },
]

const DashBoardHeader = () => {
    let location = useLocation().pathname.replace('/', '')

    return (
        <>
            <h2 style={{ padding: '20px 10px' }}>Dash board</h2>
            <Divider />
            <div style={{ padding: '20px 10px' }}>
                {navBarList.map((list) => (
                    <span
                        key={list.id}
                        style={
                            location === list.link.replace('/', '')
                                ? {
                                      color: '#EDC400',
                                      marginRight: '100px',
                                      cursor: 'pointer',
                                  }
                                : { marginRight: '100px', cursor: 'pointer' }
                        }
                        onClick={() => history.push(list.link)}
                    >
                        {list.title}
                    </span>
                ))}
            </div>
            <Divider />
        </>
    )
}

export default DashBoardHeader
