import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}))

const ReduxPagnation = ({ page, pages, func }) => {
    const classes = useStyles()
    return (
        pages > 1 && (
            <div className={classes.root}>
                <Pagination
                    page={page}
                    count={pages}
                    color="primary"
                    onChange={(event, value) => func(value)}
                />
            </div>
        )
    )
}

export default ReduxPagnation
