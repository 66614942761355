import { GET_ERRORS } from '../types'

const initialState = {
    msg: {},
}

export const errorReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ERRORS:
            return {
                ...state,
                msg: payload,
            }
        default:
            return state
    }
}
