import { Field, ErrorMessage } from 'formik'
export const fildInput = (name, label, type, error = null) => (
    <div className="form-input-div">
        <label htmlFor={name}>{label}</label>
        <Field
            name={name}
            label={label}
            type={type}
            className={`form-control`}
        />
        <ErrorMessage name={name} component="div" className="error-message" />
    </div>
)
