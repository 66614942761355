import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { fildInput } from '../module/inputForm'
import history from '../history'
import { login } from '../store/actions/designerActions'
import DisplayMessages from '../components/DisplayMessages'
import { Container } from '@material-ui/core'

const validateForm = (values) => {
    const errors = {}
    if (!values.password) {
        errors.password = 'Required'
    } else if (values.password.length < 8) {
        errors.password = 'Must be 8 characters or more'
    }

    if (!values.email) {
        errors.email = 'Required'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address'
    }
    return errors
}

const Login = () => {
    const dispatch = useDispatch()
    const recaptchaRef = useRef()
    const [remValue, setRemValue] = useState(false)
    const [email, setEmail] = useState('')
    const [loadSet, setLoadSet] = useState(false)

    const designerState = useSelector((state) => state.designer)
    const { designerInfo } = designerState

    useEffect(() => {
        const rememberEmail = localStorage.getItem('HSDesignerRemember')
            ? JSON.parse(localStorage.getItem('HSDesignerRemember'))
            : ''
        if (rememberEmail) setRemValue(true)
        setEmail(rememberEmail)
        setLoadSet(true)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (designerInfo && designerInfo?.status === 1) {
            history.replace('/welcome')
        } else if (designerInfo) {
            history.push('/')
        }
    }, [designerInfo])

    return loadSet ? (
        <Container disableGutters={true}>
            <div className="login-box">
                <div className="login-box-item-left">
                    <div className="login-box-img-box">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1642045244/Request_Design/Screen_Shot_2022-01-12_at_10.40.07_PM_jnngdz.png"
                            alt="login pic"
                            className="login-pic"
                        />
                    </div>
                </div>
                <div className="login-box-item">
                    <div className="login-box-right">
                        <h3 style={{ textAlign: 'center', padding: '30px 0' }}>
                            DESIGNER LOGIN
                        </h3>
                        <div>
                            <DisplayMessages />
                        </div>
                        <Formik
                            initialValues={{
                                email: email,
                                password: '',
                                rememberMe: remValue,
                            }}
                            validate={validateForm}
                            onSubmit={async (values) => {
                                const googleRes =
                                    await recaptchaRef.current.executeAsync()
                                recaptchaRef.current.reset()
                                values['googleRes'] = googleRes
                                dispatch(login(values))
                            }}
                        >
                            {({ isSubmitting, errors }) => (
                                <Form>
                                    {fildInput(
                                        'email',
                                        'Your email',
                                        'email',
                                        errors.email
                                    )}
                                    {fildInput(
                                        'password',
                                        'Your password',
                                        'password',
                                        errors.password
                                    )}
                                    <div className="login-remember">
                                        <label>
                                            <Field
                                                type="checkbox"
                                                name="rememberMe"
                                            />
                                            <span style={{ marginLeft: '5px' }}>
                                                Remember me
                                            </span>
                                        </label>
                                    </div>
                                    <div className="google-robert">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey={
                                                process.env
                                                    .REACT_APP_GOOGLE_SITE_KEY
                                            }
                                        />
                                    </div>

                                    <div className="login-button-area">
                                        <button
                                            type="submit"
                                            className="login-button"
                                            disabled={isSubmitting}
                                        >
                                            Login
                                        </button>
                                    </div>
                                    <div
                                        style={{
                                            paddingTop: '20px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                history.push('/forgetpassword')
                                            }
                                        >
                                            Forgot Your Password?
                                        </p>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <div className="login-join-box">
                            <h3 style={{ textAlign: 'center' }}>
                                WANT TO BECOME A DESIGNER?
                            </h3>
                            <div className="login-join-button-area">
                                <button
                                    className="login-button"
                                    onClick={() => history.push('/joinus')}
                                >
                                    Join us
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    ) : (
        ''
    )
}

export default Login
