import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { fildInput } from '../module/inputForm'
import history from '../history'

import DisplayMessages from '../components/DisplayMessages'
import { joinus } from '../store/actions/designerActions'

const validateForm = (values) => {
    const errors = {}
    if (!values.password) {
        errors.password = 'Required'
    } else if (values.password.length < 8) {
        errors.password = 'Must be 8 characters or more'
    }

    if (!values.email) {
        errors.email = 'Required'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address'
    }
    return errors
}

const Joinus = () => {
    const dispatch = useDispatch()
    const recaptchaRef = useRef()

    return (
        <div className="joinus-box">
            <div className="joinus-box-item">
                <h3 style={{ textAlign: 'center', padding: '30px 0' }}>
                    JOIN HSPACE
                </h3>
                <div>
                    <DisplayMessages />
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validate={validateForm}
                    onSubmit={async (values) => {
                        const googleRes =
                            await recaptchaRef.current.executeAsync()
                        recaptchaRef.current.reset()
                        values['googleRes'] = googleRes
                        console.log(values)
                        dispatch(joinus(values))
                    }}
                >
                    {({ isSubmitting, errors }) => (
                        <Form>
                            {fildInput(
                                'email',
                                'Your email',
                                'email',
                                errors.email
                            )}
                            {fildInput(
                                'password',
                                'Choose a password',
                                'password',
                                errors.password
                            )}

                            <div className="google-robert">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={
                                        process.env.REACT_APP_GOOGLE_SITE_KEY
                                    }
                                />
                            </div>

                            <div className="login-button-area">
                                <button
                                    type="submit"
                                    className="login-button"
                                    disabled={isSubmitting}
                                >
                                    Join
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <div className="login-join-box">
                    <h3 style={{ textAlign: 'center' }}>ALREADY A MEMBER?</h3>
                    <div className="login-join-button-area">
                        <button
                            className="login-button"
                            onClick={() => history.push('/login')}
                        >
                            Sign-in
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Joinus
