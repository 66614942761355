import { useSelector } from 'react-redux'

export const UpLoadItem = () => {
    const loading = useSelector((state) => state.loading)
    const { upLoad } = loading
    return (
        <div>
            {upLoad && (
                <>
                    <div className="loading-page">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <p>Upload {upLoad} ...</p>
                            <img
                                src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1616609279/samples/loading_mqn5mz.gif"
                                alt="loading"
                                width="150px"
                                height="150px"
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default UpLoadItem
